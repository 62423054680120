.h-screen {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.min-h-screen {
  min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}

.max-h-screen {
  max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100);
}

.credit-agreement {
  width: 75%;
  margin: 10px auto;
}

@media screen and (max-width:950px) {
  .credit-agreement {
    width: 98%;
  }
}

.table-container {
  overflow: hidden;
  border-radius: 6px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
}

.styled-table thead tr {
  background-color: #ff8c41;
  color: white;
  text-align: left;
}
.styled-table th {
  font-weight: 500;
}

.styled-table th, .styled-table td {
  padding: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 14px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1d5;
}

.status {
  display: inline-block;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
}

.status.unpaid {
  background-color: #ff8c41;
}

.status.paid {
  background-color: #4caf50;
}
